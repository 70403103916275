.Home {
  text-align: center;
  /* /* overflow-x: hidden; */
  overflow-y: visible; 
  display: block;
}

.title {
  font-size: 16px;
  /* font-weight: 500; */
  font-weight: bold;
}

.Home-banner {
  height: 340px;
  width: 100vw;
  background: linear-gradient(180deg, #CFFCF9 0%, #FDFDFD 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;

  .logo_container {
    .title {
      /* font-size: 16px; */
      /* font-weight: 500; */
      line-height: 24px;
      text-align: center;
      padding-bottom: 20px;
    }

    .logo {
      position: relative;
      /* top: 164px; */
      width: 120px;
      height: 120px;
      box-shadow: 0px 5.4px 54px -6.4px #009CCD21;
      box-shadow: 0px 14px 94px -16px #00E4D630;
      border-radius: 26.67px;
      border: 0.5px solid;
      border-image-source: linear-gradient(180deg, rgba(0, 205, 193, 0.06) 0%, rgba(0, 202, 190, 0) 100%);
    }
  }
}

.Home-container {
  top: 14.8px;
  position: relative;

  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 10px;

  .title {

    /* font-size: 16px; */
    /* font-weight: 500; */
    line-height: 24px;
    text-align: center;
  }

  .store_container {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;

    .app_store {
      img {
        padding-top: 20px;
        width: 200px;
        top: 20.6px;
      }
    }



  }

}

div.instruction {
  width: 327px;

  .title {
    padding-top: 73px;
    padding-bottom: 12px;
  }

  .content {
    padding-bottom: 58px;
  }

  /* left: 24px; */
  gap: 12px;
  position: relative;
  margin: 0 auto;
}